































import { Vue, Component, Prop } from "vue-property-decorator";
import CrmEntryCard from "@/components/CrmEntryCard.vue";
import CrmEntry from "@/models/crm-entry";
@Component({
    components: { CrmEntryCard },
})
export default class CrmColumn extends Vue {
    @Prop({ required: true, type: String }) title!: string;
    @Prop({ required: true, type: String }) chipColor!: string;
    @Prop({ required: true, type: Array }) crmEntries!: Array<CrmEntry>;
    @Prop({ required: false, type: Boolean, default: false })
    isLoading!: boolean;

    panel: Array<number> = [0];

    reload() {
        this.$emit("reload");
    }
}
