
















































import { Component, Vue } from "vue-property-decorator";
import FailedPaymentsChart from "@/components/FailedPaymentsChart.vue";
import TestOrdersChart from "@/components/TestOrdersChart.vue";
import TotalPaymentsChart from "@/components/TotalPaymentsChart.vue";
import AdminDashboardChart from "@/components/AdminDashboardChart.vue";
import CrmColumn from "@/components/CrmColumn.vue";
import CrmEntryApi from "@/services/api/crm-entry";
import { ensureAuthenticated } from "@/plugins/firebase";
import { AxiosError, AxiosResponse } from "axios";
import CrmEntry, {
    CRM_ENTRY_STATUS,
    SerializedCrmEntry,
} from "@/models/crm-entry";
import { captureSentryException } from "@/plugins/sentry";
import { ApiResponse } from "@/services/api/axios";

@Component({
    components: {
        CrmColumn,
        AdminDashboardChart,
        TotalPaymentsChart,
        TestOrdersChart,
        FailedPaymentsChart,
    },
})
export default class AdminCrmIndex extends Vue {
    isLoading = true;
    crmEntries = new Map<string, Array<CrmEntry>>();
    crmStatuses = CRM_ENTRY_STATUS;

    loadCrmEntries(setLoading = false) {
        if (setLoading) {
            this.isLoading = true;
        }
        CrmEntryApi.adminIndex()
            .then((response: AxiosResponse) => {
                const entries = new Map<string, Array<CrmEntry>>();
                Object.keys(response.data.data).forEach((status: string) => {
                    entries.set(
                        status,
                        response.data.data[status].map(
                            (entry: SerializedCrmEntry) => {
                                return new CrmEntry(entry);
                            }
                        )
                    );
                });
                this.crmEntries = entries;
                this.isLoading = false;
            })
            .catch(this.handleAxiosError);
    }

    fetchCrmEntries(status: string): Array<CrmEntry> {
        if (!this.crmEntries.has(status)) {
            return [];
        }
        return this.crmEntries.get(status) as CrmEntry[];
    }

    emitErrorNotification(error: Error) {
        this.$root.$emit(
            this.$constants.NOTIFICATION_EVENTS.ERROR,
            error.message
        );
    }

    handleAxiosError(error: AxiosError<ApiResponse>) {
        captureSentryException(error);
        this.emitErrorNotification(
            new Error(error?.response?.data?.message ?? error.message)
        );
    }

    mounted() {
        ensureAuthenticated().finally(() => {
            this.loadCrmEntries(true);
        });
    }
}
